






















































import { Vue, Component } from 'vue-property-decorator';
import Swal from 'sweetalert2'
import store from '@/store'
import router from '@/router'

@Component({
  components: {

  }
})

export default class GetExamDetail extends Vue {
  examitem: ExamDetail = null;

  onpriviouspage() {
    // let code="0";
    // if(this.examitem.InspectId!=null && this.examitem.InspectId!='')
    // {
    //   code="1";
    // }
    this.$router.push({
      path: "/GetExamList",
      //  query: {
      //   code: code
      // }
    });
  }

  onLoad() {

  }

  mounted() {
    this.examitem = {
      Specimen:'',
      InspectId:'',
      InspectName: '',
      InspectTime: '',
      InspectStatus: '',
      ReportTime: '',
      ExamTime:'',
      DeptCode:'',
      DeptName: '',
      DoctorCode:'',
      DoctorName: '',
      ExeDeptCode: '',
      ExeDeptName: '',
      Reporter:'',
      Auditor: '',
      ItemList:[]
    };

    let examid = this.$route.query.examid;

    let patsend = {
      CardNo: examid,
    };

    this.$store
      .dispatch('send', { code: 79, data: patsend })
      .then(msg => {
        if (msg.ResultFlag) {
          //console.log(msg.Item)
          this.examitem = msg.Item;
          //console.log(this.examitem)
        }
      })

      if (window.history && window.history.pushState) {
          window.addEventListener('popstate', this.backListener, false)
      }
  }

  beforeDestroy() {
      window.removeEventListener('popstate', this.backListener)
  }

  backListener() {
      this.onpriviouspage();
  }
}
