
import store from '@/store'
import router from '@/router'
import QRCode from "qrcodejs2";

export function IsWeixinOrAlipay(): number {
    let payEnv;
    // 判断微信还是支付宝
    if (/MicroMessenger/.test(window.navigator.userAgent)) {
        // 微信
        payEnv = 0;
    } else if (/AlipayClient/.test(window.navigator.userAgent)) {
        // 支付宝
        payEnv = 1;
    } else {
        payEnv = 2;
    }

    return payEnv;
}

export function gopriviouspage(name) {
    let jumppage = store.state.PriviousPath;

    if (jumppage == null || jumppage == "" || jumppage == "/Login" || jumppage == name) {
        router.push({ path: "/" });
    } else {
        router.push({ path: jumppage });
    }
}

export function showqr(id, qrcodestr, width, index=0) {
    if (document.getElementById(id) == null) {
        if (index == null) {
            index = 0;
        }
        if (index < 100) {
            index++;
            setTimeout(() => {
                showqr(id, qrcodestr, width, index);
            }, 200);
        }
    }
    else {
        document.getElementById(id).innerHTML = "";
        let qrcode = new QRCode(document.getElementById(id), {
            width: width,
            height: width,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
        });

        //qrcode.clear(); // clear the code.
        qrcode.makeCode(qrcodestr); // make another code.
    }
}

