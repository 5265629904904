
















































import { Vue, Component, Watch } from 'vue-property-decorator';
import store from '@/store'
import router from '@/router'
import Swal from 'sweetalert2'

@Component({
    components: {

    }
})

export default class ZYNetCash extends Vue {
    zyamt: string = '';
    show: boolean = false;
    patsheet: boolean = false;

    zypatitem: ZYPat = {
        ZYID: '',
        InpatientVisitNo: '',
        InpatientInTime: '',
        InpatientOfficeName: '',
        InpatientStore: '',
        InpatientCharge: '',
    };

    currentzypatid: string = '';
    zypatlist: Array<string> = [];

    get currentpat() {
        let pat= store.state.VCardList.find(x => x.PatID == this.currentzypatid);
        if(pat==null)
        {
            pat= {
                PatID: '',
                PatName: '',
                IDCardNo: '',
                PatBirth: '',
                PatAddr: '',
                TelNo: '',
                PatSex: '',
                Age: 0,
                default: false
            };
        }

        return pat;
    }

    onpriviouspage() {
        router.push({ path: "/" });
    }

    onnumpanelcomplete() {
        this.show = false;

        this.addzycash();
    }

    selectPat(patid) {
        this.patsheet = false;

        this.currentzypatid = patid;
    }

    changepat() {
        // this.$router.push({
        //     path: '/AddPat',
        //     query: {
        //         backpath: "ZYNetCash"
        //     }
        // });
        this.patsheet = true;
    }

    get patlist() {
        let patlist = [];
        store.state.VCardList.forEach(c => {
            if (this.zypatlist.includes(c.PatID)) {
                patlist.push(c);
            }
        });
        return patlist;
    }

    addzycash() {
        if (this.currentzypatid == '' || this.zyamt == '' || parseInt(this.zyamt) == 0) {
            return;
        }
        //console.log(id)
        let payitem = {
            CashType: 7,
            CardNo: this.currentzypatid,
            PayCodeList: [this.zyamt]
        };

        //debugger;

        Swal.fire({
            title: '开始提交',
            timer: 20000,
            timerProgressBar: true,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()

                this.$store
                    .dispatch('send', { code: 45, data: payitem })
                    .then(msg => {

                        if (msg.ResultFlag) {
                            if (msg.Amt > 0) {
                                window.location.href = msg.QrCode;
                                // this.$store.state.NetPayPath=msg.QrCode;
                                // this.$router.push('NetPay');
                            }
                            //Swal.close();
                        }
                        else {
                            //console.log(msg.ErrMsg);
                            Swal.fire({
                                title: msg.ErrMsg,
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            })
                        }
                    })
            },
            willClose: () => {

            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                Swal.fire({
                    title: '交易超时',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 1200
                })
            }
        })
    }

    @Watch('currentzypatid')
    onregresult(newVal: string, oldVal: string) {
        if (newVal == '')
            return;

        let patsend = {
            PatID: newVal
        };

        this.$store
            .dispatch('send', { code: 63, data: patsend })
            .then(msg => {

                //console.log(msg);
                if (msg.ResultFlag) {
                    this.zypatitem = JSON.parse(msg.Item);
                    //console.log(this.zypatitem);
                }
            })
    }

    mounted() {
        if (window.history && window.history.pushState) {
            window.addEventListener('popstate', this.backListener, false)
        }
        this.currentzypatid = '';

        this.$store
            .dispatch('send', { code: 55, data: {} })
            .then(msg => {

                //console.log(msg);
                if (msg.ResultFlag) {
                    this.zypatlist = msg.Item;

                    if (this.zypatlist.length > 0) {
                        let cpid = store.state.VCard.PatID;
                        if (this.zypatlist.includes(cpid)) {
                            this.currentzypatid = cpid;
                        }
                        else {
                            this.currentzypatid = this.zypatlist[0];
                        }
                    }
                    else
                    {
                        Swal.fire({
                    title: '没有添加住院患者',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000
                }).then(()=>{
                     this.$router.push({
            path: '/AddPat',
            query: {
                backpath: "ZYNetCash"
            }
        });
                })
                    }
                }
            })
    }

    beforeDestroy() {
        window.removeEventListener('popstate', this.backListener)
    }

    backListener() {
        //debugger;
        this.onpriviouspage();
    }
}
