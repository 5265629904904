<template>
  <div>
    <v-toolbar flat color="success" class="white--text">
      <v-btn icon dark @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>请确认挂号信息</v-toolbar-title>
    </v-toolbar>
    <v-alert dense text type="warning" class="mb-0">疫情期间，请注意社交距离</v-alert>
    <v-card color="#385F73" dark class="mx-2 mt-3">
      <v-card-title class="headline">
        <span>{{patitem.patname}}</span>
        <span class="pl-8">{{patitem.bkh}}</span>
      </v-card-title>
      <v-card-subtitle>
        <span>{{patitem.sex}}</span>
        <span class="pl-6">{{patitem.patage}}岁</span>
        <span class="pl-6">{{patitem.idcard}}</span>
      </v-card-subtitle>
    </v-card>
    <v-card outlined class="mx-2 mt-2">
      <v-card-title class="subheading font-weight-bold">挂号信息</v-card-title>
      <v-list dense>
        <v-list-item>
          <v-list-item-content>科室:</v-list-item-content>
          <v-list-item-content class="align-end">{{regitem.officename}}</v-list-item-content>
        </v-list-item>
      <!-- <v-divider></v-divider> -->
        <v-list-item>
          <v-list-item-content>医师:</v-list-item-content>
          <v-list-item-content class="align-end">{{regitem.docname}}</v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>分诊类型:</v-list-item-content>
          <v-list-item-content class="align-end">{{regitem.triagename}}</v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>挂号类型:</v-list-item-content>
          <v-list-item-content class="align-end">{{regitem.regtype}}</v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>就诊时段:</v-list-item-content>
          <v-list-item-content class="align-end warning--text font-weight-bold">{{regitem.regday}}</v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>就诊序号:</v-list-item-content>
          <v-list-item-content class="align-end warning--text font-weight-bold">{{regitem.regsort}}</v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>金额:</v-list-item-content>
          <v-list-item-content class="align-end">{{regitem.regamt}}元</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-footer absolute height="50px" color="black" class="white--text py-0 px-0">
      <v-row>
        <v-col cols="8" class="title pl-7">¥{{regitem.regamt}}</v-col>
        <v-col cols="4" class="success pl-0 pt-2">
          <v-btn text block color="white" class="subtitle-1" height="100%">确认挂号</v-btn>
        </v-col>
      </v-row>

      <!-- <v-btn color="primary" class="">Primary</v-btn> -->
    </v-footer>
  </div>
</template>

<script>
  export default {
    name: 'RegPayConfirm',
    props: {//dialog = false
      regitem: {
        type: Object,
        default: () => ({
          officename: undefined,
          docname: undefined,
          triagename: undefined,
          regtype: undefined,
          regday: undefined,
          regsort:undefined,
          regamt:undefined
        }),
      },
      patitem: {
        type: Object,
        default: () => ({
          patname: undefined,
          bkh: undefined,
          patage: undefined,
          idcard: undefined,
          sex: undefined,
        }),
      },
      dialog: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style>
/* .v-application--wrap {
  background-color: #ffffff;
} */
</style>


