













import { Vue, Component, Watch } from 'vue-property-decorator';
import PatEdit from '../components/PatEdit.vue'
import store from '@/store'
import '../Model/MsgPkg'
import { gopriviouspage } from '../tools/extra'
import Swal from 'sweetalert2'

@Component({
    components: {
        PatEdit
    }
})

export default class CreatePerson extends Vue {
    loginpat: Person = {
                PatID: '',
                PatName: '',
                IDCardNo: '',
                PatBirth: '',
                PatAddr: '',
                TelNo: '',
                PatSex: '',
                Age: 0,
                default: false
            };

    vcardno:string='';
    oldpatid:string='';
    editmode:string='';

    onpriviouspage() {
        //debugger;
        gopriviouspage('/CreatePerson')
    }

    GetPatNum() {
        return store.state.VCardList.length;
    }

    // @Watch('GetPatNum')
    // onChangeValue(newVal: number, oldVal: number) {
    //     if ((newVal>0)) {
    //         this.$router.push('/');
    //     }
    // }

    onSubmit() {
        //debugger;
        var cardlogin = {
            PatName: this.loginpat.PatName,
            IDCardNo: this.loginpat.IDCardNo,
            TelNo: this.loginpat.TelNo,
            Default: this.loginpat.default
        };

        // store.commit('send',{code:3,data:cardlogin});

        Swal.fire({
            title: '开始提交',
            timer: 20000,
            timerProgressBar: true,
            allowOutsideClick:false,
            didOpen: () => {
                Swal.showLoading()

                this.$store
                    .dispatch('send', { code: 3, data: cardlogin })
                    .then(msg => {
                        //Swal.close();
                        if (msg.ResultFlag) {
                            Swal.fire({
                                title: "提交成功",
                                icon: 'success',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            }).then(() => {
                                this.onpriviouspage();
                            });
                        }
                        else {
                            //console.log(msg.ErrMsg);
                            Swal.fire({
                                title: msg.ErrMsg,
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            })
                        }
                    })
            }
        })
    }

    onDelete(){
        var carddelete = {
            CardNo: this.vcardno,
        };

        // store.commit('send',{code:3,data:cardlogin});

        Swal.fire({
            title: '开始提交',
            timer: 20000,
            timerProgressBar: true,
            allowOutsideClick:false,
            didOpen: () => {
                Swal.showLoading()

                this.$store
                    .dispatch('send', { code: 76, data: carddelete })
                    .then(msg => {
                        //Swal.close();
                        if (msg.ResultFlag) {
                            Swal.fire({
                                title: "删除成功",
                                icon: 'success',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            }).then(() => {
                                this.onpriviouspage();
                            });
                        }
                        else {
                            //console.log(msg.ErrMsg);
                            Swal.fire({
                                title: msg.ErrMsg,
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            })
                        }
                    })
            }
        })
    }

    ongetoldpat(){
        var carddelete = {
            CardNo: this.oldpatid,
        };

        Swal.fire({
            title: '开始提交',
            timer: 20000,
            timerProgressBar: true,
            allowOutsideClick:false,
            didOpen: () => {
                Swal.showLoading()

                this.$store
                    .dispatch('send', { code: 11, data: carddelete })
                    .then(msg => {
                        
                        if (msg.ResultFlag) {
                            Swal.close();
                            this.loginpat=msg.Item;
                        }
                        else {
                            //console.log(msg.ErrMsg);
                            Swal.fire({
                                title: msg.ErrMsg,
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            })
                        }
                    })
            }
        })
    }

    storecache() {
        this.$store.commit('store');
    }

    mounted() {
        let createtype = this.$route.query.createtype;
        this.editmode = Array.isArray(createtype) ? createtype[0] : createtype;
        switch (this.editmode) {
            case "0":
                this.loginpat = {
                    PatID: '',
                    PatName: '',
                    IDCardNo: '',
                    PatBirth: '',
                    PatAddr: '',
                    TelNo: '',
                    PatSex: '',
                    Age: 0,
                    default: false
                };
                this.vcardno = '';
                break;
            case "1":
                var vc = store.state.VCardList.find(c => c.PatID == store.state.EditCardNo);
                this.loginpat = vc;
                this.vcardno = vc.PatID;
                break;
        }

        if (window.history && window.history.pushState) {
            window.addEventListener('popstate', this.backListener, false)
        }
    }

    backListener() {
        this.onpriviouspage();
    }

    beforeDestroy() {
        store.state.EditCardNo='';
        
        window.removeEventListener('popstate', this.backListener)
    }
}
