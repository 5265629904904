
















import { Vue, Component, Ref } from 'vue-property-decorator';
import { TreeSelect } from 'vant';
import store from '@/store'
import router from '@/router'

@Component({
  components: {

  }
})

export default class GH extends Vue {
  @Ref('doctree') doctree!: TreeSelect;

  searchfilter: string = "";
  activeIndex: number = 0;
  activeId: number = 0;
  windowheight: number = 300;
  // items: Array<vanitem> = [
  //   {
  //     text: '所有城市',
  //     className: '',
  //     children: [
  //       {
  //         text: '温州',
  //         id: "aa",
  //       },
  //       {
  //         text: '杭州',
  //         id: "bb",
  //       },
  //     ],
  //   },
  //   {
  //     text: '所有城市',
  //     className: '',
  //     children: [
  //       {
  //         text: '温州',
  //         id: "aa",
  //       },
  //       {
  //         text: '杭州',
  //         id: "bb",
  //       },
  //     ],
  //   }
  // ];

  onpriviouspage(){
    router.push({ path: "/" });
  }

  getOfficeList() {
    return store.state.Reg.OfficeList;
  }

  created() {
    window.addEventListener('resize', this.getHeight);
    this.getHeight();
  }

  mounted() {
    if (window.history && window.history.pushState) {
      window.addEventListener('popstate', this.backListener, false)
    }

    store.commit('send', { code: 73, data: { OfficeVersion: store.state.Reg.OfficeVersion } });
  }

  beforeDestroy() {
    window.removeEventListener('popstate', this.backListener)
  }

  backListener() {
    this.onpriviouspage();
  }

  getHeight() {
    this.windowheight = window.innerHeight - 100;
  }

  clickitem(data) {
    //console.log(data.id);
    store.state.Reg.CurrentOfficeName = data.text;
    store.state.Reg.CurrentOfficeCode = data.id;
    router.push({ path: "/Reg" });
  }
}
