








































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Dialog } from 'vant';
import store from '@/store'
import router from '@/router'
import RegPayConfirm from '../components/RegPayConfirm.vue'
import Swal from 'sweetalert2'

@Component({
    components: {
        [Dialog.Component.name]: Dialog.Component,
        RegPayConfirm,
    }
})

export default class CreatePerson extends Vue {
    calshow: boolean = false;
    caldate: string = '';
    rdfztype: string = '';
    patselect: string = '';
    patmenumshow: boolean = false;
    confirmsheet: boolean = false;
    regdoc: string = "";
    regamt: number = 0;

    onpriviouspage() {
        router.push({ path: "/GHOffice" });
    }

    onCalConfirm(date) {
        this.calshow = false;
        this.caldate = this.formatDate(date);
    }

    get currentPat() {
        return this.$store.state.VCard;
    }

    getregresult(){
        //return store.state.Reg.Result;
        return store.state.Regresult;
    }

    @Watch('getregresult')
    onregresult(newVal: string, oldVal: string) {
        debugger;
        alert('test:'+newVal)
        if ((newVal!='' && oldVal=='')) {
            if(newVal=='0')
            {
                Swal.fire({
                                title: '挂号成功',
                                icon: 'success',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            }).then(() => {
                                router.push({ path: "/GHOffice" });
                            })
            }
            else
            {
                Swal.fire({
                                title: newVal,
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            }).then(() => {
                                router.push({ path: "/GHOffice" });
                            })
            }
        }
    }

    showPat() {
        this.patmenumshow = true;
    }

    selectPat(id) {
        //console.log(id);
        this.$store.commit('changecurrentpat', id);
        this.patmenumshow = false;
    }

    formatDate(time: any) {
        // 格式化日期，获取今天的日期
        const Dates = new Date(time);
        const year: number = Dates.getFullYear();
        const month: any = (Dates.getMonth() + 1) < 10 ? '0' + (Dates.getMonth() + 1) : (Dates.getMonth() + 1);
        const day: any = Dates.getDate() < 10 ? '0' + Dates.getDate() : Dates.getDate();
        return year + '-' + month + '-' + day;
    }

    gettoday() {
        return this.formatDate(new Date());
    }

    getdayend(n) {
        return new Date(new Date().getTime() + (1000 * 3600 * 24 * n));
    }

    getdoclist() {
        return this.$store.state.Reg.docitems;
    }

    @Watch('rdfztype')
    onChangeValue(newVal: string, oldVal: string) {
        var doc = this.$store.state.Reg.docitems.find(x => x.MapIndex == newVal);
        //debugger;
        if (doc == null) {
            this.regdoc = "";
            this.regamt = 0;
        }
        else {
            this.regdoc = doc.DocName;
            this.regamt = doc.TotalCost;
        }
    }

    getcurrentofficename() {
        return this.$store.state.Reg.CurrentOfficeName;
    }

    get patlist() {
        //debugger;
        return this.$store.state.VCardList;
    }

    storecache() {
        this.$store.commit('store');
    }

    mounted() {
        if (window.history && window.history.pushState) {
            window.addEventListener('popstate', this.backListener, false)
        }

        this.rdfztype = '';//getdoclist()
        if (store.state.Reg.CurrentOfficeCode != '') {
            //store.commit('send', { code: 74, data: { OfficeCode: store.state.Reg.CurrentOfficeCode } });

            this.$store
                .dispatch('send', { code: 74, data: { OfficeCode: store.state.Reg.CurrentOfficeCode } })
                .then(msg => {
                    //Swal.close();
                    if (msg.ResultFlag) {
                        if (msg.DocList.length == 0) {
                            Swal.fire({
                                title: '无可挂医生',
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            }).then(() => {
                                router.push({ path: "/GHOffice" });
                            })
                        }
                    }
                    else {
                        //console.log(msg.ErrMsg);
                        Swal.fire({
                            title: msg.ErrMsg,
                            icon: 'error',
                            showCancelButton: false,
                            showConfirmButton: false,
                            timer: 1200
                        })
                    }
                })
        }
        else {
            this.$router.push('GHOffice');
        }
    }

    reg() {
        //debugger;
        store.state.Regresult='100';
        //console.log(this.rdfztype);
        if (this.rdfztype != '') {
            this.confirmsheet = true;
        }
    }

    payconfirm() {
        // store.state.Regresult='100';
        // console.log("[1]regresult:"+store.state.Regresult);

        // return ;

        let payitem = {
            CashType: 4,
            CardNo: this.currentPat.PatID,
            PayCodeList: [this.rdfztype]
        };

        // store.commit('send', { code: 45, data: payitem });
        this.confirmsheet = false;
        Swal.fire({
            title: '开始提交',
            timer: 20000,
            timerProgressBar: true,
            allowOutsideClick:false,
            //backdrop:false,
            didOpen: () => {
                Swal.showLoading()

                this.$store
                    .dispatch('send', { code: 45, data: payitem })
                    .then(msg => {
                        //Swal.close();
                        //debugger;
                        if (msg.ResultFlag) {
                            if (msg.QrCode != '') {
                                window.location.href = msg.QrCode;
                                // this.$store.state.NetPayPath=msg.QrCode;
                                // this.$router.push('NetPay');
                            }
                        }
                        else {
                            //console.log(msg.ErrMsg);
                            Swal.fire({
                                title: msg.ErrMsg,
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            })
                        }
                    })
            },
            willClose: () => {

            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                Swal.fire({
                    title: '交易超时',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 1200
                })
            }
        })
    }

    beforeDestroy() {
        window.removeEventListener('popstate', this.backListener)
    }

    backListener() {
        this.onpriviouspage();
    }
}
