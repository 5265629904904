


























import { Component, Prop,Watch,PropSync,Emit, Vue } from 'vue-property-decorator'

type  Person = {
    Id: string;
    Title: string;
    Votes: string;
    Year: string;
    Rating: string;
    Date: string;
    RestfulAssembly: string;
}

Component.registerHooks([
  'beforeRouteLeave',
  'beforeRouteEnter',
]);

@Component
export default class HelloWorld extends Vue {
  /*
  替代以前的传入传出值，ref替代
  属性参数：@Prop(options: (PropOptions | Constructor[] | Constructor) = {})
  参数说明：@Prop装饰器接收一个参数，这个参数可以有三种写法：
  PropOptions，可以使用以下选项：type，default，required，validator;
  Constructor[]，指定 prop 的可选类型；
  Constructor，例如String，Number，Boolean等，指定 prop 的类型；

  注意：属性的ts类型后面需要加上undefined类型；或者在属性名后面加上!，表示非null 和 非undefined的断言，告诉TypeScript我这里一定有值，否则编译器会给出错误提示；
  等同于
  export default {
  props: {
    propA: {
      type: Number
    },
    propB: {
      default: 'default value'
    },
    propC: {
      type: [String, Boolean]
    },
  }
  }
  */
  // @Prop(Number) propA!: number;
  @Prop({ default: 'default value' }) propB!: string;
  // @Prop([String, Boolean]) propC: string | boolean;

  @PropSync('like', { type: String }) syncedlike!: string; // 用来实现组件的双向绑定,子组件可以更改父组件穿过来的值

  //等同data
  ValA: string = 'hello world';
  ValB: number = 1;
  ValC: number = 1;

  calshow:boolean=false;
  caldate:string='';

  //等同computed
  get ValResult() {
    return this.ValB + 10;
  }

  editLike(): void {
    this.syncedlike = '子组件修改过后的syncedlike!'; // 双向绑定,更改syncedlike会更改父组件的like
  }

  //在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图
  private created(){
    this.init();
  }

  //在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作
  mounted(){

  }

  private init() {
    console.log('init');
  }

  AddVal(val: number) {
    this.ValB = val + 1;
  }

  public AddValB() {
    this.ValB = this.ValB + 1;
  }

  //替代watch，
  @Watch('ValB')
  onChangeValue(newVal: string, oldVal: string) {
    // todo...
    this.ValC=parseInt(newVal)+100;
  }

  /*
  watch: {
        'child': this.onChangeValue, // 这种写法默认 `immediate`和`deep`为`false`
        'person': {
            handler: 'onChangeValue',
            immediate: true,
            deep: true
        }
    },
  1.第一个handler：其值是一个回调函数。即监听到变化时应该执行的函数。
  2.第二个是deep：其值是true或false；确认是否深入监听。（一般监听时是不能监听到对象属性值的变化的，数组的值变化可以听到。）
  3.第三个是immediate：其值是true或false；确认是否以当前的初始值执行handler的函数。
  */
  @Watch('person', { immediate: true, deep: true })
  onChangePerson(newVal: Person, oldVal: Person) {
    // todo...
  }

  @Emit()
  handleEmit1(n: string) {
    console.log('触发handleEmit1')
  }

  @Emit('test')
  handleTest(n: string) {
    console.log('触发handleTest')
  }

  beforeRouteEnter(to: any, from: any, next: any) {
    console.log('beforeRouteEnter')
    next()
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    console.log('beforeRouteLeave')
    next()
  }

  onCalConfirm(date) {
    this.calshow = false;
    this.caldate = this.formatDate(date);
  }

  formatDate( time: any ){
      // 格式化日期，获取今天的日期
      const Dates = new Date( time );
      const year: number = Dates.getFullYear();
      const month: any = ( Dates.getMonth() + 1 ) < 10 ? '0' + ( Dates.getMonth() + 1 ) : ( Dates.getMonth() + 1 );
      const day: any = Dates.getDate() < 10 ? '0' + Dates.getDate() : Dates.getDate();
      return year + '-' + month + '-' + day;
    }

  getdayend(n) {
    return new Date(new Date().getTime() + ( 1000 * 3600 * 24 * n ));
  }
}
