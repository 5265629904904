










































































import { Vue, Component } from 'vue-property-decorator';
import Swal from 'sweetalert2'
import store from '@/store'
import router from '@/router'
import { showqr } from '../tools/extra'

@Component({
  components: {

  }
})

export default class GetPayList extends Vue {
  paylist: Array<UITran> = [];
  loading: boolean = false;
  finished: boolean = false;
  dialog: boolean = false;
  notealert:boolean = false;

  onpriviouspage() {
    this.$router.push({ path: "/" });
  }

  showtranqr(code)
  {
    this.dialog=true;

    showqr("qrcodelogin", code, 280);
  }


  onLoad() {
    let patsend = {
      PatID: store.state.VCard.PatID,
    };

    this.$store
      .dispatch('send', { code: 67, data: patsend })
      .then(msg => {
        this.loading = false;
        //console.log(msg);
        if (msg.ResultFlag) {
          this.paylist = msg.Item;
          this.finished = true;
        }
      })
  }

  mounted() {
    this.paylist = [];
    this.notealert=true;

    if (window.history && window.history.pushState) {
      window.addEventListener('popstate', this.backListener, false)
    }
  }

  beforeDestroy() {
    window.removeEventListener('popstate', this.backListener)
  }

  backListener() {
    this.onpriviouspage();
  }
}
