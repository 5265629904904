




























import { Vue, Component, Ref } from 'vue-property-decorator';
import HelloWorld from '../components/HelloWorld.vue';

@Component({
    components: {
        HelloWorld, // 声明子组件的引用
    }
})

export default class Home extends Vue {
    //实体化控件
    @Ref('HelloWorldComponent') HelloWorldComponent!: HelloWorld;

    like: string = "";

    created() {
        this.like = this.$store.state.patname;
    }

    AddChild() {
        this.HelloWorldComponent.AddValB();
    }

    handleEmit1Parent(val) {
        console.log('handleEmit1Parent:' + val)
    }

    handleTest(val) {
        console.log('handleTest')
    }
}
