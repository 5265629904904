




































































































import { Vue, Component } from 'vue-property-decorator';
import Swal from 'sweetalert2'

@Component({
    components: {

    }
})

export default class EpReport extends Vue {
    pataddr: string = '';
    test: string = '';
    questionlist: Array<string> = ['0', '0', '0', '0', '0'];
    questiongrouplist: Array<string[]> = [['0'], ['0'], ['0']];
    attend: AttendInfo = {
        patname: '',
        idcardno: '',
        telno: '',
        addr: ''
    };

    mzpatitem: MZPat = {
        MZID: ''
    };


    year: number = new Date().getFullYear();
    month: any = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
    day: any = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate();

    getDate(date, i) {
        if (date === undefined || date === null) {
            date = new Date();
        }
  
        date.setTime(date.getTime() + i * 24 * 60 * 60 * 1000);
        
        return date;
    }

    get NextDayYear() {
        var date= this.getDate(null, 1)
        return date.getFullYear();
    }

    get NextDayMonth() {
        var date= this.getDate(null, 1)
        let month = date.getMonth() + 1 < 10 ? '0' + parseInt(date.getMonth() + 1) : date.getMonth() + 1;
        return month;
    }

    get NextDayDay() {
        var date= this.getDate(null, 1)
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        return day;
    }

    get Pat() {
        return this.$store.state.VCard;
    }

    onpriviouspage() {
        this.$router.push({ path: "/" });
    }

    submit() {
        var r = this.checkresult();
        //debugger;
        if (r) {
            let epitem = {
                PatID: this.Pat.PatID,
                Note: {
                    day: new Date(),
                    questionlist: this.questionlist,
                    questiongrouplist: this.questiongrouplist,
                    pataddr: this.pataddr,
                    attend: this.attend
                }
            };


            this.$store
                .dispatch('send', { code: 81, data: epitem })
                .then(msg => {
                    this.$router.push('GHOffice');
                })
        }

        //console.log(r);
        // if (r) {
        //     if(this.pataddr=='')
        //     {
        //         Swal.fire({
        //         title: '未填写地址',
        //         icon: 'error',
        //         showCancelButton: false,
        //         showConfirmButton: false,
        //         timer: 1200
        //     })
        //     }
        //     else
        //     {
        //     this.$router.push('GHOffice');
        //     }
        // }
        // else {
        //     Swal.fire({
        //         title: '对不起，您无法就诊',
        //         icon: 'error',
        //         showCancelButton: false,
        //         showConfirmButton: false,
        //         timer: 1200
        //     }).then(() => {
        //         this.$router.push('/');
        //     })
        // }
    }

    checkresult() {
        // if (this.mzpatitem.MZID == '') {
        //     Swal.fire({
        //         title: '未找到门诊信息',
        //         icon: 'error',
        //         showCancelButton: false,
        //         showConfirmButton: false,
        //         timer: 1200
        //     })

        //     return false;
        // }

        // for (let i = 0, length = this.questionlist.length; i < length; i++) {
        //     if (this.questionlist[i] != '0') {
        //         return false;
        //     }
        // }

        for (let i = 0, length = this.questiongrouplist.length; i < length; i++) {
            // if (this.questiongrouplist[i].length > 1) {
            //     return false;
            // }

            // let errresult = this.questiongrouplist[i].indexOf('0');
            // if (errresult == -1) {
            //     return false;
            // }

            if (this.questiongrouplist[i].length == 0) {
                return false;
            }
        }

        if (this.pataddr == '') {
            Swal.fire({
                title: '未填写地址',
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1200
            })

            return false;
        }

        if (this.questionlist[4] == '1') {
            if (this.attend.patname == '' || this.attend.idcardno == '' || this.attend.addr == '' || this.attend.telno == '') {
                Swal.fire({
                    title: '陪护信息不完整',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 1200
                })

                return false;
            }
        }

        return true;
    }


    mounted() {
        if (window.history && window.history.pushState) {
            window.addEventListener('popstate', this.backListener, false)
        }


        // let epnote={
        //             questionlist: this.questionlist,
        //             questiongrouplist: this.questiongrouplist,
        //             pataddr: this.pataddr,
        //             attend: this.attend
        //         };

        let epnoteoldstr = localStorage.getItem('epnote-' + this.Pat.PatID);

        if (epnoteoldstr != null && epnoteoldstr != "") {
            let epnote = JSON.parse(epnoteoldstr);
            this.questionlist = epnote.questionlist;
            this.questiongrouplist = epnote.questiongrouplist;
            this.pataddr = epnote.pataddr;
            this.attend = epnote.attend;
        }

        // let patsend = {
        //     PatID: this.Pat.PatID,
        // };

        // this.$store
        //     .dispatch('send', { code: 62, data: patsend })
        //     .then(msg => {

        //         //console.log(msg);
        //         if (msg.ResultFlag) {
        //             this.mzpatitem = JSON.parse(msg.Item);
        //             //console.log(this.zypatitem);
        //         }
        //     })
    }

    beforeDestroy() {
        window.removeEventListener('popstate', this.backListener)

        let epnote = {
            questionlist: this.questionlist,
            questiongrouplist: this.questiongrouplist,
            pataddr: this.pataddr,
            attend: this.attend
        };

        localStorage.setItem('epnote-' + this.Pat.PatID, JSON.stringify(epnote));
    }

    backListener() {
        this.onpriviouspage();
    }
}
