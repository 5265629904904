







































































import { Vue, Component, Watch, Prop, Emit, PropSync } from 'vue-property-decorator';

@Component({
  components: {

  }
})

export default class CreatePerson extends Vue {
  @PropSync('dialog', { default: false }) dialogopen!: boolean;
  @Prop({ default: '' }) docname!: string;
  @Prop({ default: 0 }) regamt!: number;

  getcurrentPat() {
    return this.$store.state.VCard;
  }

  getcurrentofficename() {
    return this.$store.state.Reg.CurrentOfficeName;
  }


  @Emit('pay')
  payconfirm() {

  }

}

