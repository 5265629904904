export function isMobile(mobile: string): boolean {
  if (mobile == null)
    return false;

    var tel = /^0\d{2,3}-?\d{7,8}$/;
    var phone = /^1[3-9][0-9]{9}$/; 
    if(mobile.length == 11){//手机号码
      if(phone.test(mobile)) {

        return true;
      }
    }else if(mobile.length == 12 && mobile.indexOf("-") != -1 ){//电话号码
      if(tel.test(mobile)) {

        return true;
      }
    }

    return false;

}
