import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{attrs:{"left-arrow":""},on:{"click-left":_vm.onpriviouspage},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"nav_title"},[_c('h3',[_vm._v("门诊缴费")])])]},proxy:true}])}),_c(VRow,{staticClass:"my-1"},[_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"pl-3 pt-2 subtitle-1"},[_vm._v("共"),_c('b',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.jflist.length))]),_vm._v("张处方，总计"),_c('b',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.getallamt()))]),_vm._v("元 ")])]),_c(VCol,{staticClass:"pt-4 pl-8",attrs:{"cols":"4"}},[_c(VBtn,{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.jfall()}}},[_vm._v(" 全部缴费 ")])],1)],1),_vm._l((_vm.jflist),function(jfitem){return _c('div',{key:jfitem.RcptGroupId},[_c(VCard,{staticClass:"mx-auto",attrs:{"width":"96%","outlined":"","rounded":""}},[_c(VCardText,[_c(VRow,{staticClass:"text-subtitle-1"},[_c(VCol,{attrs:{"cols":"8"}},[_c('b',{staticClass:"orange--text text--darken-4"},[_vm._v(_vm._s(jfitem.RcptGroupId))])]),_c(VCol,{attrs:{"cols":"4"}},[_c('b',{staticClass:"orange--text text--darken-4"},[_vm._v(_vm._s(jfitem.Sum)+"元")])])],1),_vm._l((jfitem.Items),function(detailitem){return _c(VRow,{key:detailitem.ItemNo,staticClass:"text-subtitle-1"},[_c(VCol,{attrs:{"cols":"8"}},[_c('span',[_vm._v(_vm._s(detailitem.ItemName))])]),_c(VCol,{attrs:{"cols":"4"}},[_c('span',[_vm._v(_vm._s(detailitem.Fyxj)+"元")])])],1)})],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"deep-purple accent-4"},on:{"click":function($event){return _vm.jfsingle(jfitem.RcptGroupId)}}},[_vm._v(" 缴费本张单 ")])],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }