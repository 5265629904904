















































































import { Vue, Component } from 'vue-property-decorator';
import store from '@/store'
import router from '@/router'
import Swal from 'sweetalert2'
import '../Model/PayModel'

@Component({
    components: {

    }
})

export default class JF extends Vue {
    jflist: Array<ClientJFModel> = [];
    panel:Array<number>=[0];

    onpriviouspage() {
        router.push({ path: "/" });
    }

    getallamt() {
        let amt = 0;

        if (this.jflist.length > 0) {
           
            this.jflist.forEach(j => {
                amt = (100*amt + j.Sum*100)/100;
            });
        }
        return amt;
    }

    get currentPat() {
        return this.$store.state.VCard;
    }

    jfsingle(id){
        //console.log(id)
        let payitem = {
            CashType: 5,
            CardNo: this.currentPat.PatID,
            PayCodeList: [id]
        };

        //debugger;
        //console.log(this.currentPat)

        Swal.fire({
        title: '开始提交',
        timer: 20000,
        timerProgressBar: true,
        allowOutsideClick:false,
        didOpen: () => {
            Swal.showLoading()

            this.$store
                    .dispatch('send', { code: 45, data: payitem })
                    .then(msg => {
                       
                        if (msg.ResultFlag) {
                            //Swal.close();
                            
                            if (msg.Amt > 0) {
                                window.location.href = msg.QrCode;
                                // this.$store.state.NetPayPath=msg.QrCode;
                                // this.$router.push('NetPay');
                            }
                            //Swal.close();
                        }
                        else {
                            //console.log(msg.ErrMsg);
                            Swal.fire({
                                title: msg.ErrMsg,
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            })
                        }
                    })
        },
        willClose: () => {
           
        }
        }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
            Swal.fire({
                                title: '交易超时',
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            })
        }
        })
    }

    jfall(){
        //debugger;
        let rcplist=[];

        if(this.jflist.length==0)
        return;
        
        this.jflist.forEach(j=>{
            rcplist.push(j.RcptGroupId)
        });

        let payitem = {
            CashType: 5,
            CardNo: this.currentPat.PatID,
            PayCodeList: rcplist
        };

        Swal.fire({
        title: '开始提交',
        timer: 20000,
        timerProgressBar: true,
        allowOutsideClick:false,
        didOpen: () => {
            Swal.showLoading()

            this.$store
                    .dispatch('send', { code: 45, data: payitem })
                    .then(msg => {
                        if (msg.ResultFlag) {
                            if (msg.Amt > 0) {
                                window.location.href = msg.QrCode;
                                // this.$store.state.NetPayPath=msg.QrCode;
                                // this.$router.push('NetPay');
                            }
                            //Swal.close();
                        }
                        else {
                            //console.log(msg.ErrMsg);
                            Swal.fire({
                                title: msg.ErrMsg,
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            })
                        }
                    })
        },
        willClose: () => {
           
        }
        }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
            Swal.fire({
                                title: '交易超时',
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            })
        }
        })
    }

    mounted() {
        this.jflist = [];

        let patsend = {
            PatID: store.state.VCard.PatID,
        };

        Swal.fire({
        title: '开始查询',
        timer: 20000,
        timerProgressBar: true,
        allowOutsideClick:false,
        didOpen: () => {
            Swal.showLoading()

            this.$store
            .dispatch('send', { code: 77, data: patsend })
            .then(msg => {
                if (msg.ResultFlag) {
                    this.jflist = msg.JFList;
                    this.panel=[0];
                    if (this.jflist.length == 0) {
                        Swal.fire({
                            title: '无待缴处方',
                            icon: 'error',
                            showCancelButton: false,
                            showConfirmButton: false,
                            timer: 1200
                        }).then(() => {
                            router.push({ path: "/" });
                        })
                    }
                    else
                    {
                        Swal.close();
                    }
                }
                else {
                    //console.log(msg.ErrMsg);
                    Swal.fire({
                        title: msg.ErrMsg,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        timer: 1200
                    }).then(() => {
                        router.push({ path: "/" });
                    })
                }
            })
        }
        })

        if (window.history && window.history.pushState) {
            window.addEventListener('popstate', this.backListener, false)
        }
    }
    
    beforeDestroy() {
        window.removeEventListener('popstate', this.backListener)
    }

    backListener() {
        this.onpriviouspage();
    }
}
