
















import { Vue, Component } from 'vue-property-decorator';

@Component({
    components: {

    }
})

export default class NetPay extends Vue {
    get NetPayUrl() {
        return this.$store.state.NetPayPath;
    }

    onpriviouspage() {
        this.$router.push({ path: "/" });
    }
}
