






































import { Vue, Component } from 'vue-property-decorator';
import store from '../store/index'
import { gopriviouspage } from '../tools/extra'
import Swal from 'sweetalert2'

@Component({
    components: {

    }
})

export default class AddPat extends Vue {
    backpath:string='';

    onpriviouspage() {
        this.$router.push('/');
    }

    backurl() {
        if (this.backpath == '') {
            this.$router.push('/');
        }
        else {
            this.$router.push('/' + this.backpath);
        }
    }

    test()
    {
        console.log('a')
    }

    selectdefault(patid) {
        var vc = store.state.VCardList.find(c => c.PatID == patid);

        var cardlogin = {
            PatName: vc.PatName,
            IDCardNo: vc.IDCardNo,
            TelNo: vc.TelNo,
            Default: true
        };

        Swal.fire({
            title: '开始提交',
            timer: 20000,
            timerProgressBar: true,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()

                this.$store
                    .dispatch('send', { code: 3, data: cardlogin })
                    .then(msg => {
                        //Swal.close();
                        if (msg.ResultFlag) {
                            // Swal.fire({
                            //     title: "切换成功",
                            //     icon: 'success',
                            //     showCancelButton: false,
                            //     showConfirmButton: false,
                            //     timer: 1200
                            // }).then(() => {
                            //     this.onpriviouspage();
                            // });
                            Swal.close();
                            this.backurl();
                        }
                        else {
                            //console.log(msg.ErrMsg);
                            Swal.fire({
                                title: msg.ErrMsg,
                                icon: 'error',
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1200
                            })
                        }
                    })
            }
        })
    }

    editpat(patid) {
        store.state.EditCardNo = patid;
        this.$router.push({
            path: '/CreatePerson',
            query: {
                createtype: "1"
            }
        }
        );
    }

    checkcurrentpat(cardno) {
        if (store.state.VCard == null)
            return false;
        return store.state.VCard.PatID == cardno;
    }

    AddPat(){
        this.$router.push({
            path: '/CreatePerson',
            query: {
                createtype: "0"
            }
        }
        );
    }

    getpatlist() {
        return store.state.VCardList;
    }

    mounted() {
        store.state.EditCardNo = '';

        let bp = this.$route.query.backpath;

        if (bp == null) {
            this.backpath = '';
        }
        else {
            this.backpath = Array.isArray(bp) ? bp[0] : bp;
        }

        if (window.history && window.history.pushState) {
            window.addEventListener('popstate', this.backListener, false)
        }
    }

    beforeDestroy() {
        window.removeEventListener('popstate', this.backListener)
    }

    backListener() {
        this.onpriviouspage();
    }
}
