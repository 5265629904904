
































import { Vue, Component, Ref, Prop,Emit,PropSync } from 'vue-property-decorator';
import { Dialog, Field, Form } from 'vant';
import { isMobile } from '../tools/validate/mobile'
import { isIDCard } from '../tools/validate/idcard'

@Component({
    components: {
        [Dialog.Component.name]: Dialog.Component,
    }
})

export default class PatEdit extends Vue {
    @Ref('namefield') namefield!: Field;
    @Ref('idcardfield') idcardfield!: Field;
    @Ref('telfield') telfield!: Field;
    @Ref('persondetail') persondetail!: Form;

    get edittitle(){
        if(this.loginpat.PatID=='')
        {
        return '新患者，请填入患者基本信息';
        }
        else
        {
            return '患者基本信息';
        }
    }

    // @Prop({ default: {
    //     vcardno:'',
    //     patname: '',
    //     idcardno: '',
    //     telno: '',
    // } }) pat!: VCard;
    @Prop({ default: '0' }) editmode!: string;
    //注意这里pat和loginpat不能同名，否则报错
    @PropSync('pat', { type: Object }) loginpat!: Person;
    @PropSync('patid', { type: String }) oldpatid!: string;

    // loginpat: Person = {
    //     vcardno:'',
    //     patname: '',
    //     idcardno: '',
    //     telno: '',
    // };
    //value: string = "";
    //autoperson: boolean = false;
    //isSaving: boolean = false;

    telValidator(val) {
        return isMobile(val);
    }

    idcardValidator(val) {
        return isIDCard(val);
    }

    onDelete() {
        Dialog.confirm({
            title: "确定要删除么",
        })
            .then(() => {
                //this.$emit('delete', this.data);
                //console.log('delete')
                this.deletepat();
            })
            .catch(() => {
                //console.log('cancel-delete')
                //this.$emit('cancel-delete', this.data);
            });
    }

    onSubmit() {
        this.persondetail.validate().then(()=>{
            this.SavePat();
        }).catch(function(err){
            console.log(err);
        })

        
        //debugger;
        // if (isValid && !this.isSaving) {
        //     //this.$emit('save', this.pat);

        //     this.SavePat(this.pat);
        //     // this.$store.state.Pat = this.pat;
        //     // this.storecache();
        //     // console.log('保存' + this.pat)
        //     //this.$router.push('/');
        // }
    }

    @Emit('savepat')
    SavePat()
    {
        //console.log('savepateve');
    }

    @Emit('deletepat')
    deletepat()
    {
        //console.log('savepateve');
    }

    @Emit('getoldpat')
    getoldpat()
    {
        //console.log('savepateve');
    }
}

