






import { Vue, Component } from 'vue-property-decorator';
import Swal from 'sweetalert2'

@Component({
  components: {

  }
})

export default class Error extends Vue {
  mounted() {
      let exmsg = this.$route.query.ex;

      Swal.fire({
                    title: exmsg,
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 1200
                }).then(()=>{
                  this.$router.push({ path: "/" });
                })
}
}
