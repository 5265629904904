import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{attrs:{"left-arrow":""},on:{"click-left":_vm.onpriviouspage},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"nav_title"},[_c('h3',[_vm._v("检验报告单")])])]},proxy:true}])}),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c(VList,{attrs:{"three-line":""}},[_vm._l((_vm.examlist),function(item,index){return [(item.header)?_c(VSubheader,{key:index,domProps:{"textContent":_vm._s(item.header)}}):(item.divider)?_c(VDivider,{key:index + '-d',attrs:{"inset":item.inset}}):_c(VListItem,{key:index + '-l',on:{"click":function($event){return _vm.turndetial(item.key)}}},[(item.avatartxt!='')?_c(VListItemAvatar,{attrs:{"color":item.avatarcolor,"size":"36"}},[_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(item.avatartxt))])]):_c(VListItemAvatar,{attrs:{"size":"36"}},[_c(VIcon,{class:item.avatarcolor,attrs:{"dark":""},domProps:{"textContent":_vm._s(item.avatar)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(item.title)}}),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(item.subtitle)}})],1),_c(VListItemAction,[_c(VListItemActionText,{staticClass:"text-h6",domProps:{"textContent":_vm._s(item.actiontitle)}}),_c(VListItemActionText,{domProps:{"textContent":_vm._s(item.actionSubtitle)}})],1)],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }