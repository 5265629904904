






import { Vue, Component } from 'vue-property-decorator';
import Swal from 'sweetalert2'

@Component({
  components: {

  }
})

export default class Demo extends Vue {
  jhstr:string='https://ibsbjstar.ccb.com.cn/CCBIS/QR?QRCODE=CCB9980001377039147874058';
  dates:Array<string>= ['2019-09-10', '2019-09-20'];

    loaded () {
//       const _iframe = document.getElementById('iframe');
// const cookie = document.cookie
// _iframe.postMessage(cookie, this.jhstr)
}

    items:Array<Object>= [
        { header: 'Today' },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Brunch this weekend?',
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
          subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Oui oui',
          subtitle: '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          title: 'Birthday gift',
          subtitle: '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
          title: 'Recipe to try',
          subtitle: '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
        },
      ];

    list:Array<number>= [];
      loading:boolean= false;
      finished:boolean= false;

      onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    }

    get dateRangeText () {
        return this.dates.join(' ~ ')
      }

  test() {
    Swal.fire({
      title: '开始提交',
      timer: 20000,
      timerProgressBar: true,
      allowOutsideClick:false,
      //backdrop: false,
      hideClass: {
        popup: 'swal2-hide',
        backdrop: 'swal2-backdrop-hide',
        icon: 'swal2-icon-hide'
      },
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {

      }
    })
  }
}
