






import { Vue, Component, Watch } from 'vue-property-decorator';
// import { socketBus, loginEvent, send } from '@/plugins/websocket'
import axios from 'axios'
import store from '@/store'
import router from '@/router'

@Component({
    components: {

    }
})

export default class Login extends Vue {
    info: string = '加载中....';

    mounted() {
        //console.log('login-mounted')
    }
    beforeDestroy() {
        //console.log('login-beforeDestroy')
        //socketBus.$off(loginEvent)
    }

    get GetSessionID() {
        return store.state.SessionID;
    }

    @Watch('GetSessionID')
    onChangeValue(newVal: string, oldVal: string) {
        if ((newVal != '') && (oldVal == '')) {
            this.toJumpPage();
        }
    }

    created() {
        //console.log('login-created')
        // socketBus.$on(loginEvent, (val) => {
        //     //debugger;
        //     console.log(val)
        //     store.state.SessionID = val;
        //     this.toJumpPage();
        // })
        //debugger;
        let code = this.$route.query.code;
        if (code) {
            // axios({
            //     method: 'get',
            //     url: "http://scqyhyy.com:9200/Wechat/GetUserInfo",
            //     params: {
            //         code: code
            //     }
            // }
            // )
            //     .then(response => {
            //         //debugger;
            //         //console.log(response);
            //         if (response.data) {
            //             let openid = response.data.OpenID;
            //             localStorage.setItem('openid', openid);
            //             store.state.OpenID = openid;
            //             this.toJumpPage();
            //         }
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     });
            this.info = "开始连接"
            //store.commit('send', { code: 75, data: { WXCode: code } });
            const sendfun = function (s) {
                if (!s.state.socket.isConnected) {
                    setTimeout(() => {
                        sendfun(s)
                    }, 500);
                }
                else {
                    s.commit('send', { code: 75, data: { WXCode: code } });
                }
            };

            sendfun(store);
        } else {
            //this.getCodeApi();
            //debugger;

            // if (store.state.OpenID == '') {
            //     var openid = localStorage.getItem('openid');

            //     if ((!openid) || openid == '') {
            //         this.getCodeApi();
            //     }
            //     else {
            //         store.state.OpenID = openid;
            //     }
            // }

            // var openid = localStorage.getItem('openid');

            // if (!openid) {
            //     openid = '1axxx';
            //     localStorage.setItem('openid', openid);
            // }
            // store.state.OpenID = openid;
            //debugger;
            if (this.GetSessionID != '') {
                this.toJumpPage();
            }
            else {
                this.info = "开始获取参数"
                //this.getCodeApi();

                if (store.state.OpenID == '') {
                    var openid = localStorage.getItem('openid');

                    if ((!openid) || openid == '') {
                        //this.getCodeApi();
                    }
                    else {
                        store.state.OpenID = openid;
                    }
                }

                this.info = "尝试连接服务器"
                if (store.state.OpenID != '') {
                    let login = {
                        UserID: store.state.OpenID,
                    };
                    store.commit('send', { code: 0, data: login });
                }
                else {
                    this.getCodeApi();
                    // store.state.OpenID = 'oyLzd6i4cRv_iT_StbvHr1whBp6I';
                    // localStorage.setItem('openid', store.state.OpenID);
                }
            }

            // const loginfun = async () => {
            //     for (var i = 0; ; i++) {
            //         console.log("login start")
            //         let loginend = await fun();
            //         if (loginend)
            //         {
            //             break;
            //         }
            //         else{
            //             this.info="服务器连接中。。。"
            //         }
            //     }
            //     console.log("login end")
            // };

            // if (!this.loginMac(openid)) {
            //     loginfun();
            // }
        }
    }
    // loginMac(openid) {
    //     var login = {
    //         UserID: openid,
    //     };
    //     var sendresult = send(0, login);
    //     return sendresult;
    // }
    getCodeApi() {
        let state = 'STATE';
        //获取code
        //let urlNow = window.location.href.split("#")[0];
        let urlNow="http://scqyhyy.com/Login";
        
        let scope = "snsapi_base"; //snsapi_userinfo   //静默授权 用户无感知
        let appid = "wxa0b96e7be411c9ec";
        let url =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            appid +
            "&redirect_uri=" +
            urlNow +
            "&response_type=code&scope=" +
            scope +
            "&state=" +
            state +
            "#wechat_redirect";

            debugger;
        window.location.replace(url);
        //     axios
        //   .get(url)
        //   .then(
        //     response => (this.info = response.data)
        //     )
        //   .catch(function (error) { // 请求失败处理
        //     console.log(error);
        //   });
    }

    toJumpPage() {
        //debugger;
        let jumppage = store.state.JumpPath;
        //console.log("跳转:" + jumppage);
        if (jumppage == null || jumppage == "" || jumppage == '/Login') {
            router.push({ path: "/" });
        } else {
            router.push({ path: jumppage });
        }
    }
}
