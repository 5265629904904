















































import { Vue, Component } from 'vue-property-decorator';
import Swal from 'sweetalert2'
import store from '@/store'
import router from '@/router'

@Component({
  components: {

  }
})

export default class GetExamList extends Vue {
  //examlist: Array<UITran> = [];
  loading: boolean = false;
  finished: boolean = false;

  onpriviouspage() {
    this.$router.push({ path: "/" });
  }

  get exam(){
    return store.state.Exam;
  }

  get examlist() {
    let list;
    if (this.exam == null)
      list = [];
    else
      list = this.exam.examlist;

    if (list.length > 0 && list[0] == null) {
      list = [];
    }

    // console.log('cc')
    // console.log(list);

    return list;
  }

  onLoad() {
    //debugger;
    if (this.exam.pageid == '' || (this.exam.pageid != '' && this.exam.pagecount > this.exam.pageindex+1)) {
      let patsend = {
        PatID: store.state.VCard.PatID,
        PageID:this.exam.pageid,
        Index:(this.exam.pageindex+1)
      };

      this.$store
        .dispatch('send', { code: 78, data: patsend })
        .then(msg => {
          //debugger;
          this.loading = false;
          //console.log(msg);
          
          if (msg.ResultFlag) {
            this.exam.currentpat = store.state.VCard.PatID;
            this.exam.examlist=this.exam.examlist.concat(msg.Item.Items);
            this.exam.pagecount = msg.Item.PageCount;
            this.exam.pageid = msg.Item.PageID;
            this.exam.pageindex = msg.Item.Index;
            //console.log(this.exam.examlist);
            if ((this.exam.pageindex + 1) == this.exam.pagecount) {
              this.finished = true;
            }
          }
          else{
            this.finished = true;
          }
        })
    }
    else
    {
      this.loading = false;
      this.finished = true;
    }
  }

  turndetial(id)
  {
      this.$router.push({
          path: '/GetExamDetail',
          query: {
              examid: id
          }
      });
  }

  mounted() {
    // let code = this.$route.query.code;

    // if (code == "1") {
    //   store.state.Exam.examlist = [];
    //   this.loading = false;
    //   this.finished = true;
    // }
    // else
    // {
    //   this.loading = false;
    //   this.finished = false;
    // }
    //console.log('2')
    if (store.state.VCard.PatID != this.exam.currentpat) {
      this.exam.examlist = [];
      this.exam.pagecount = 0;
      this.exam.pageid = '';
      this.exam.pageindex = -1;
    }

    if (window.history && window.history.pushState) {
          window.addEventListener('popstate', this.backListener, false)
      }
  }

  beforeDestroy() {
      window.removeEventListener('popstate', this.backListener)
  }

  backListener() {
      this.onpriviouspage();
  }
}
